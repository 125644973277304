import React, { Fragment } from "react";
import AbstractComponent from "../../AbstractComponent";
import ModalQuestion from "../../layout/modal/ModalQuestion";
import Image from "../../layout/image/Image";
import Menu from "../../layout/menu/Menu";
import Config from "../../../Config";

import UsuarioService from "../../../service/UsuarioService";
import AutenticarService from "../../../service/AutenticarService";
import ClienteService from "../../../service/ClienteService";
import EmpresaService from "../../../service/EmpresaService";

import TipoRedeSocialEnum from "../../../helper/enumerador/TipoRedeSocialEnum";
import EmpresaFreteService from "../../../service/EmpresaFreteService";

class MenuPerfil extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      foto_perfil: "",

      exit: false,

      menu: [],
      empresa: {},
    };
  }

  header() {
    return (
      <div
        className="col-md-12
                            col-sm-12
                            col-lg-12
                            col-xs-12"
        style={{
          height: "77px",
          backgroundColor: "#ffffff",
          marginBottom: "5px",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRigth: "10px",
        }}
      >
        <Image
          md={3}
          xs={3}
          sm={3}
          lg={3}
          height={"4em"}
          heightImage={"4em"}
          width={"4em"}
          semImagem={Config.urlImgBase + "login.png"}
          imagem={this.state.foto_perfil}
          borderRadius="50px"
          semImagemBorderRadius="50px"
        />

        <div
          className="col-md-9
                                col-sm-9
                                col-lg-9
                                col-xs-9"
          style={{
            padding: "12px 5px",
          }}
        >
          <div className="row user-menu__name">{this.props.cliente.nome}</div>

          <div
            className="row user-menu__email"
            style={{ color: "#a6a5a5", fontSize: "11px" }}
          >
            {this.props.cliente.email}
          </div>
        </div>
      </div>
    );
  }

  footer() {
    return (
      <div
        style={{
          fontSize: "11px",
          backgroundColor: "#F2F2F2",
          padding: "20px 30px",
          bottom: "0px",
          marginTop: "10px",
        }}
      >
        Desenvolvido por
        <br />
        Avance Sistemas e Consultoria LTDA
        <br />
        CNPJ: 10.276.888/0001-43
        <br />
        37701-024 - Rua Piauí, 178
        <br />
        Centro - Poços de Caldas-MG
        <br />
        <br />
        AppShop - versão {Config.versao}
      </div>
    );
  }

  menuSair() {
    return (
      <a
        className={"btn-navigation btn-navigation-mobile bg-white"}
        onClick={() => this.setState({ exit: true })}
      >
        <div
          className="col-md-2
                                col-sm-2
                                col-lg-2
                                col-xs-2
                                text-center"
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
          onClick={() => this.setState({ exit: true })}
        >
          <span className="btn-navigation__icon text-center">
            <span className="icon-search icon-search--order">
              <i className="icon-submenu-font fas fa-sign-out-alt" />
            </span>
          </span>
        </div>

        <div
          className="col-md-8
                                col-sm-8
                                col-lg-8
                                col-xs-8
                                btn-navigation__label"
          style={{ paddingLeft: "0px" }}
          onClick={() => this.setState({ exit: true })}
        >
          Sair
        </div>
      </a>
    );
  }

  logout() {
    UsuarioService.logout()
      .then(() => {
        AutenticarService.logout();

        this.setState({ preload: false });

        this.homePage2();
      })
      .catch(() => {
        AutenticarService.logout();

        this.setState({ preload: false });

        this.homePage2();
      });
  }

  onClick(item) {
    if (item.link !== "" || item.submenu === undefined) {
      this.props.onClick(item);

      return;
    }

    this.setState({ active: item.nome });
  }

  submenuConfiguracao() {
    let submenu = [];

    submenu.push(
      this.addMenu(
        "pe-7s-bell",
        "Notificações",
        "notificacoes",
        "configuracao",
        Config.urlBase + "configuracao/notificacao"
      )
    );

    return submenu;
  }

  async componentDidMount() {
    let menus = [];    

    if(localStorage.getItem('clienteFidelidade') == 'contemFidelidade'){
      menus.push(
        this.addMenu(
          "pe-7s-cash",
          "Cupons de Desconto",
          "cupons",
          "principal",
          Config.urlBase + "cupons"
        )
      );
    }else {
      try {
        const responseEmpresa = await EmpresaService.validaEmpresa();
        let empresa = this.state.empresa;
        empresa = responseEmpresa.data;
        this.setState({ empresa: empresa });
  
        if (this.state.empresa.usa_programa_fidelidade) {
          let docCliente = AutenticarService.getDocumentoLocal();
          if (docCliente !== false) {
            const responseContemCadastro =
              await ClienteService.getContemCadastroLocal(docCliente);
            if (responseContemCadastro.data.cadastradoLocal === true) {
              menus.push(
                this.addMenu(
                  "pe-7s-cash",
                  "Cupons de Desconto",
                  "cupons",
                  "principal",
                  Config.urlBase + "cupons"
                )
              );
              localStorage.setItem('clienteFidelidade', 'contemFidelidade');
            }else if(responseContemCadastro.data.cadastradoLocal === false){
              localStorage.setItem('clienteFidelidade', 'naoContemFidelidade');
            }
          }
        }
      } catch (error) {}

    }

    menus.push(
      this.addMenu(
        "pe-7s-ticket",
        "Pedidos",
        "pedidos",
        "principal",
        Config.urlBase + "pedidos"
      )
    );

    menus.push(
      this.addMenu(
        "pe-7s-bell",
        "Notificações",
        "notificacoes",
        "principal",
        Config.urlBase + "configuracao/notificacao"
      )
    );

    menus.push(
      this.addMenu(
        "pe-7s-users",
        "Dados pessoais",
        "dados",
        "principal",
        Config.urlBase + "perfil"
      )
    );

    menus.push(
      this.addMenu(
        "pe-7s-map-2",
        "Endereços",
        "enderecos",
        "principal",
        Config.urlBase + "enderecos"
      )
    );
    menus.push(
      this.addMenu(
        "pe-7s-key",
        "Alterar senha",
        "senha",
        "principal",
        Config.urlBase + "senha"
      )
    );
    menus.push(
      this.addMenu(
        "fa fa-user-times",
        "Excluir Conta",
        "excluir",
        "principal",
        Config.urlBase + "excluir"
      )
    );

    menus.push(
      this.addMenu(
        "pe-7s-info",
        "Sobre",
        "sobre",
        "principal",
        Config.urlBase + "sobre"
      )
    );
    menus.push(
      this.addMenu(
        "pe-7s-lock",
        "Política de Privacidade",
        "politica",
        "principal",
        Config.urlBase + "politica-de-privacidade"
      )
    );
    menus.push(
      this.addMenu(
        "fa fa-exclamation-triangle",
        "Termos de Uso",
        "termos",
        "principal",
        Config.urlBase + "termos-de-uso"
      )
    );

    // EmpresaService.validaEmpresa().then((response) => {
    //   let empresa = this.state.empresa;
    //   empresa = response.data;

    //   this.setState({ empresa: empresa });

    //   if (this.state.empresa.usa_programa_fidelidade) {
    //     let docCliente = AutenticarService.getDocumentoLocal();
    //     if (docCliente !== false) {
    //       ClienteService.getContemCadastroLocal(docCliente).then((response) => {
    //         if (response.data.cadastradoLocal == true) {
    //           alert('adicionando');
    //           menus.push(
    //             this.addMenu(
    //               "pe-7s-cash",
    //               "Cupons de Desconto",
    //               "cupons",
    //               "principal",
    //               Config.urlBase + "cupons"
    //             )
    //           );
    //         }
    //       });
    //     }
    //   }
    // });

    ClienteService.getByCpf(AutenticarService.getDocumentoCliente())
      .then((response) => {
        let foto_perfil = this.state.foto_perfil;
        const tipoLogin = AutenticarService.getTipoLogin();

        if (tipoLogin === TipoRedeSocialEnum.FACEBOOK.enumName) {
          foto_perfil = response.data.foto_facebook;
        } else if (tipoLogin === TipoRedeSocialEnum.GOOGLE.enumName) {
          foto_perfil = response.data.foto_google;
        } else {
          if (response.data.foto_perfil !== "") {
            foto_perfil = Config.urlImgCliente + response.data.foto_perfil;
          }
        }

        this.setState({ foto_perfil: foto_perfil });
      })
      .catch((erro) => {});
    this.setState({ menu: menus });
  }

  render() {
    return (
      <Fragment>
        <ModalQuestion
          show={this.state.exit}
          texto="Tem certeza de que deseja sair da conta?"
          btnSimClick={() => this.logout()}
          btnNaoClick={() => this.setState({ exit: false })}
          fechar={() => this.setState({ exit: false })}
        />
        <Menu
          header={this.header()}
          footer={this.footer()}
          menu={this.state.menu}
          extraMenu={this.menuSair()}
          onClick={(item) => this.onClick(item)}
        />
      </Fragment>
    );
  }
}

export default MenuPerfil;
