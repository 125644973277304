import Config from "../Config";
import Requisicao from "./RequisicaoService";

class ClienteService {   
    static async cadastro(dados) {
        return Requisicao.post(Config.urlBaseApi + 'cliente', dados);
    }    

    static async verificarCPF(documentoCliente) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/verificar/CPF', {            
            documentoCliente: documentoCliente            
        });
    }

    static async verificarCPFGoogle(documentoCliente, idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/verificar/CPF/google', {            
            documentoCliente: documentoCliente,
            idRedeSocial: idRedeSocial,
        });
    }

    static async verificarCPFFacebook(documentoCliente, idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/verificar/CPF/facebook', {            
            documentoCliente: documentoCliente,
            idRedeSocial: idRedeSocial,
        });
    }

    static async verificarCPFApple(documentoCliente, idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/verificar/CPF/apple', {            
            documentoCliente: documentoCliente,
            idRedeSocial: idRedeSocial,
        });
    }

    static async verificarFacebook(idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/verificar/facebook', {
            idRedeSocial: idRedeSocial,
        });
    }

    static async verificarGoogle(idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/verificar/google', {            
            idRedeSocial: idRedeSocial
        });
    }  
    
    static async verificarApple(idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/verificar/apple', {
            idRedeSocial: idRedeSocial
        });
    } 

    static async cliente(cpf) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/cliente', {            
            cpf: cpf
        });
    }

    static async getByCpf(documentoCliente) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/getByCpf', {            
            documentoCliente: documentoCliente
        });
    }

    static async gravarDadosPessoais(dados) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/gravarDadosPessoais', dados);
    }        

    static async setIdFacebook(documentoCliente, id, foto) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/setIdFacebook', {            
            documentoCliente: documentoCliente,
            id: id,
            foto: foto
        });
    }

    static async setIdGoogle(documentoCliente, id, foto) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/setIdGoogle', {            
            documentoCliente: documentoCliente,
            id: id,
            foto: foto
        });
    }

    static async setIdApple(documentoCliente, id, foto) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/setIdApple', {            
            documentoCliente: documentoCliente,
            id: id,
            foto: foto
        });
    }

    static async removerFoto(documentoCliente) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/removerFoto', {            
            documentoCliente: documentoCliente,
        });
    }          
    
    static async getByHash(myHash) {
        return Requisicao.get(Config.urlBaseApi + 'cliente/hash/' + myHash);
    }

    static async getContemCadastroLocal(docCliente){
        return Requisicao.get(Config.urlBaseApi + 'cliente/cadastroLocal/' + docCliente);
    }
}

export default ClienteService;