import Config from '../Config';

class AutenticarService {
    static setEmpresa(empresa, perfilSistema, shoppingBag) {        
        localStorage.setItem('nomeEmpresa', empresa);
        localStorage.setItem('perfilSistema', perfilSistema);
        localStorage.setItem('shoppingBag', shoppingBag);                     
    }        

    static getNomeEmpresa() {
        return localStorage.getItem('nomeEmpresa');
    }

    static getPerfilSistema() {
        return localStorage.getItem('perfilSistema');
    }

    static getShoppingBag() {
        return localStorage.getItem('shoppingBag');
    }    

    static clearEmpresa() {        
        localStorage.removeItem('nomeEmpresa');
    }

    static logar(token, documentoCliente, userName, tipoLogin) {
        localStorage.setItem('x-token', token);
        localStorage.setItem('documentoCliente', documentoCliente);
        localStorage.setItem('userName', userName);        
        localStorage.setItem('tipoLogin', tipoLogin);        

        window.location.href = Config.urlBase;
    }

    static getToken() {
        return localStorage.getItem('x-token');
    }

    static getDocumentoCliente() {
        return localStorage.getItem('documentoCliente');
    }

    static getUserName() {
        return localStorage.getItem('userName');
    }

    static getTipoLogin() {
        var tipoLogin = localStorage.getItem('tipoLogin');

        return tipoLogin.toUpperCase();
    }

    static setUserName(userName) {
        localStorage.setItem('userName', userName);
    }    

    static logout() {
        localStorage.removeItem('x-token');
        localStorage.removeItem('documentoCliente');
        localStorage.removeItem('userName');              
        localStorage.removeItem('tipoLogin'); 
        localStorage.removeItem('clienteFidelidade');
    }

    static setIdCarrinho(id) {
        localStorage.setItem('idCarrinho', id);
    }

    static getIdCarrinho() {
        var id = localStorage.getItem('idCarrinho');
        
        if(id === undefined) {
            id = 0;
        } else if(id === null) {
            id = 0
        } else if(id === '') {
            id = 0;
        }

        return id;
    }

    static getNotificacaoOk() {
        var value = localStorage.getItem('notificacaoOk');

        if(value === null)
            return false;        

        return value === '1';
    }

    static setNotificacaoOk(tipo) {        
        localStorage.setItem('notificacaoOk', tipo);
    }

    static setUuidAparelho(uuid) {        
        localStorage.setItem('uuid_aparelho', uuid);
    }

    static getUuidAparelho() {
        var value = localStorage.getItem('uuid_aparelho');

        if(value === undefined) {
            return 'undefined';
        }

        if(value === null) {
            return 'undefined';
        }

        return value;        
    }

    static getDocumentoLocal(){
        var docCliente = localStorage.getItem('documentoCliente');
        if(docCliente == 'undefined'){
            return false;
        }else{
            return docCliente;
        }

    }
}

export default AutenticarService;